import clsx from "clsx"

import type { AvatarProps } from "@mui/material/Avatar"
import type { IExpertEntry, IAdvisorStatus } from "../../../types"

import { Avatar, Box, Hidden } from "../../_common"
import NextImage from "../../_common/NextImage"
import StatusIndicator from "../StatusIndicator"
import classes from "./Avatar.module.scss"

interface ISize {
  width: number
  height: number
}

interface IAvatar
  extends Pick<IExpertEntry, "displayName" | "photo">,
    AvatarProps {
  width?: number | string
  onlineStatus?: IAdvisorStatus | undefined
  onlineStatusClassName?: string
  imgSizes?: {
    mobile: ISize
    desktop: ISize
  }
}

const ExpertsAvatar: React.FC<IAvatar> = ({
  photo,
  displayName,
  onlineStatus,
  className,
  onlineStatusClassName,
  imgSizes,
  ...avatarProps
}) => {
  const imageProps = {
    src: photo,
    alt: displayName,
  }

  const avatarPropsFinal = {
    ...avatarProps,
    className: classes.avatar,
  }

  return (
    <Box className={clsx(classes.root, className)}>
      <Box
        sx={{
          height: {
            xs: imgSizes?.mobile.height || "100%",
            md: imgSizes?.desktop.height || "100%",
          },
          width: {
            xs: imgSizes?.mobile.width || "inherit",
            md: imgSizes?.desktop.width || "inherit",
          },
        }}
      >
        {imgSizes && photo ? (
          <Avatar {...avatarPropsFinal}>
            <Hidden mdUp>
              <NextImage {...imageProps} {...imgSizes.mobile} />
            </Hidden>
            <Hidden mdDown>
              <NextImage {...imageProps} {...imgSizes.desktop} />
            </Hidden>
          </Avatar>
        ) : (
          <Avatar {...imageProps} {...avatarPropsFinal} />
        )}
      </Box>
      {onlineStatus && (
        <StatusIndicator
          onlineStatus={onlineStatus}
          className={onlineStatusClassName}
        />
      )}
    </Box>
  )
}

export default ExpertsAvatar
