import React from "react"

import { IPromotion, IPromotionAdvisor, PromotionType } from "../../types"
import { IPromotionBaseRestProps } from "./types"
import { FreeProductPromotion, Coupon } from "./PromotionTypes"
import { ICouponBaseProps } from "./PromotionTypes/Coupon"
import { useRuntimeConfig } from "../../hooks/runtimeConfig"
import { useTranslations } from "next-intl"

interface IPromotionVariationProps
  extends IPromotionBaseRestProps,
    Omit<ICouponBaseProps, "expireAt" | "advisor"> {
  promotion: IPromotionAdvisor | IPromotion | undefined | null
  withSwitcher?: boolean
  useDefaultPromotionText?: boolean
}

const PromotionVariation: React.FC<IPromotionVariationProps> = ({
  promotion,
  variation,
  useDefaultPromotionText,
  ...rest
}) => {
  const { getRuntimeConfigByKey } = useRuntimeConfig()
  const translate = useTranslations()

  if (!promotion) {
    return null
  }

  switch (promotion.type) {
    case PromotionType.FREE_CREDIT:
    case PromotionType.FREE_MINUTES:
    case PromotionType.DISCOUNT:
      return (
        <Coupon
          expireAt={promotion.expire_at}
          advisor={"advisor" in promotion ? promotion.advisor : null}
          variation={variation}
          {...rest}
        >
          {useDefaultPromotionText
            ? translate("productFlow.promotions.marketplace.title")
            : promotion.title}
        </Coupon>
      )
    case PromotionType.WELCOME_FREE_MINUTES: {
      const freeChatsEnabled = getRuntimeConfigByKey("FREE_CHAT_IS_ENABLED")
      const productPrefix = !freeChatsEnabled ? "Anruf: " : ""
      const text = `${productPrefix}${promotion.free_minutes} min geschenkt`

      if (variation === "coupon") {
        return (
          <Coupon
            expireAt={promotion.expire_at}
            advisor={"advisor" in promotion ? promotion.advisor : null}
            variation={variation}
            {...rest}
          >
            {text}
          </Coupon>
        )
      }

      return (
        <FreeProductPromotion variation={variation} {...rest}>
          {text}
        </FreeProductPromotion>
      )
    }
    case PromotionType.OTHER:
      return null
    default:
      return <>{promotion.title}</>
  }
}

export default PromotionVariation
