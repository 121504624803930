import { useRef, useEffect } from "react"

/**
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @param callback - effect to execute after delay
 * @param delay - delay in milliseconds
 * @param runImmediately - should it run without vaiting for the first interval to pass. `false` by default
 */
function useInterval(
  callback: () => void,
  delay: number | null,
  runImmediately = false
): void {
  const savedCallback = useRef<() => void>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      // @ts-ignore
      savedCallback.current()
    }

    if (delay !== null) {
      if (runImmediately) {
        tick()
      }

      const id = window.setInterval(tick, delay)

      return () => clearInterval(id)
    }

    return undefined
  }, [delay, runImmediately])
}

export default useInterval
