import { CustomerGenderEnum } from "../../../types"

import { UserState } from "../types"

export const defaultUserState: UserState = {
  initialLoadReady: false,
  productFlowSettings: null,
  user: {
    errors: undefined,
    details: {
      email: undefined,
      login: undefined,
      personal: {
        birthday_day: null,
        birthday_month: null,
        birthday_year: null,
        first_name: "",
        last_name: "",
        gender: CustomerGenderEnum.UNKNOWN,
        zodiac_sign: null,
        phone: "",
      },
      phones: [],
    },
  },
  verifyEmail: {
    loading: true,
    error: undefined,
    result: undefined,
  },
  resendVerificationEmail: {
    loading: undefined,
    error: undefined,
    result: undefined,
  },
  preferencesData: {
    groups: [],
    error: undefined,
    loading: undefined,
  },
}
