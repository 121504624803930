import React from "react"

import { IIconProps } from "./types"
import IconBase from "./IconBase"
import { useCheckBrand } from "./utils/useCheckBrand"

const CouponIcon: React.FC<IIconProps> = ({ className }) => {
  const { isQuesticoBrand } = useCheckBrand()

  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg width="100%" height="100%" viewBox="0 0 20 14" fill="none">
          <path
            d="M20 3.91V0H0V4C0.575794 4.275 1.06198 4.70737 1.40234 5.24711C1.7427 5.78685 1.92333 6.41191 1.92333 7.05C1.92333 7.68809 1.7427 8.31315 1.40234 8.85289C1.06198 9.39263 0.575794 9.825 0 10.1V14H20V10.09L19.88 10C19.3121 9.71278 18.8369 9.27108 18.509 8.72573C18.181 8.18038 18.0137 7.55353 18.0262 6.91729C18.0388 6.28105 18.2307 5.66128 18.5798 5.12927C18.929 4.59726 19.4213 4.17463 20 3.91ZM18.5 3.05C17.8781 3.50388 17.3721 4.09821 17.0233 4.7846C16.6744 5.47099 16.4926 6.23006 16.4926 7C16.4926 7.76994 16.6744 8.52901 17.0233 9.2154C17.3721 9.9018 17.8781 10.4961 18.5 10.95V12.5H1.5V10.88C2.10077 10.4249 2.58798 9.8369 2.92342 9.16199C3.25886 8.48709 3.43343 7.74367 3.43343 6.99C3.43343 6.23633 3.25886 5.49291 2.92342 4.81801C2.58798 4.1431 2.10077 3.55507 1.5 3.1V1.5H18.5V3.05Z"
            fill="currentColor"
          />
          <path
            d="M14.0283 2.21875L5.21777 11.0293L6.03095 11.8425L14.8415 3.03192L14.0283 2.21875Z"
            fill="currentColor"
          />
          <path
            d="M7.22012 3.9998C7.34868 3.9998 7.47435 4.03793 7.58124 4.10935C7.68813 4.18077 7.77144 4.28229 7.82064 4.40106C7.86984 4.51983 7.88271 4.65053 7.85763 4.77661C7.83255 4.9027 7.77064 5.01852 7.67974 5.10942C7.58883 5.20033 7.47301 5.26223 7.34693 5.28732C7.22084 5.3124 7.09015 5.29952 6.97137 5.25033C6.8526 5.20113 6.75109 5.11782 6.67966 5.01093C6.60824 4.90403 6.57012 4.77836 6.57012 4.6498C6.57012 4.56444 6.58693 4.47992 6.6196 4.40106C6.65226 4.3222 6.70014 4.25054 6.7605 4.19019C6.82086 4.12983 6.89251 4.08195 6.97137 4.04928C7.05024 4.01662 7.13476 3.9998 7.22012 3.9998ZM7.22012 2.7998C6.85422 2.7998 6.49654 2.90831 6.19231 3.11159C5.88808 3.31487 5.65096 3.6038 5.51094 3.94184C5.37092 4.27988 5.33428 4.65186 5.40567 5.01072C5.47705 5.36959 5.65324 5.69923 5.91197 5.95795C6.1707 6.21668 6.50034 6.39287 6.8592 6.46426C7.21807 6.53564 7.59004 6.499 7.92808 6.35898C8.26612 6.21896 8.55506 5.98184 8.75834 5.67761C8.96162 5.37338 9.07012 5.0157 9.07012 4.6498C9.05966 4.16612 8.86014 3.70578 8.51432 3.36744C8.16851 3.0291 7.70392 2.83969 7.22012 2.8398V2.7998Z"
            fill="currentColor"
          />
          <path
            d="M12.7699 8.90996C12.8985 8.90996 13.0242 8.94808 13.131 9.01951C13.2379 9.09093 13.3212 9.19244 13.3704 9.31122C13.4196 9.42999 13.4325 9.56068 13.4074 9.68677C13.3824 9.81286 13.3204 9.92868 13.2295 10.0196C13.1386 10.1105 13.0228 10.1724 12.8967 10.1975C12.7706 10.2226 12.64 10.2097 12.5212 10.1605C12.4024 10.1113 12.3009 10.028 12.2295 9.92108C12.158 9.81419 12.1199 9.68852 12.1199 9.55996C12.1199 9.38757 12.1884 9.22224 12.3103 9.10034C12.4322 8.97844 12.5975 8.90996 12.7699 8.90996ZM12.7699 7.70996C12.404 7.70996 12.0463 7.81846 11.7421 8.02174C11.4379 8.22502 11.2008 8.51395 11.0607 8.852C10.9207 9.19004 10.8841 9.56201 10.9555 9.92088C11.0269 10.2797 11.203 10.6094 11.4618 10.8681C11.7205 11.1268 12.0501 11.303 12.409 11.3744C12.7679 11.4458 13.1398 11.4092 13.4779 11.2691C13.8159 11.1291 14.1049 10.892 14.3081 10.5878C14.5114 10.2835 14.6199 9.92586 14.6199 9.55996C14.6199 9.06931 14.425 8.59876 14.0781 8.25181C13.7311 7.90487 13.2606 7.70996 12.7699 7.70996Z"
            fill="currentColor"
          />
        </svg>
      ) : (
        <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none">
          <path
            d="M16.6 10C16.6 8.6 17.7 7.5 19.1 7.5V5.4C19.1 3.5 17.6 2 15.8 2H4.3C2.5 2 1 3.5 1 5.4V7.9C2.4 7.9 3.5 9 3.5 10.4C3.5 11.8 2.4 12.9 1 12.9V13.7C1 16 2.8 17.9 5.1 17.9H14.9C17.2 17.9 19 16 19 13.7V12.4C17.7 12.5 16.6 11.4 16.6 10Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.2 8.69981C8.13888 8.69981 8.9 7.93869 8.9 6.9998C8.9 6.06092 8.13888 5.2998 7.2 5.2998C6.26112 5.2998 5.5 6.06092 5.5 6.9998C5.5 7.93869 6.26112 8.69981 7.2 8.69981Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.9998 14.4999C13.9387 14.4999 14.6998 13.7387 14.6998 12.7999C14.6998 11.861 13.9387 11.0999 12.9998 11.0999C12.0609 11.0999 11.2998 11.861 11.2998 12.7999C11.2998 13.7387 12.0609 14.4999 12.9998 14.4999Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.9999 7L6.3999 13.6"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default CouponIcon
