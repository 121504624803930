import React from "react"
import clsx from "clsx"

import { Box, CustomTooltip, Typography } from "../../_common"
import InfoIcon from "../../_common/QuesticoIcon/InfoIcon"
import { formatDate, isLessThenXDays } from "../../MyQuestico/VoucherInfo/utils"
import AlertsIcon from "../../_common/QuesticoIcon/AlertsIcon"
import { TPromotionVariation, IPromotionBaseRestProps } from "../types"
import PromotionBase, {
  PromotionBaseRoot,
  PromotionBaseBody,
} from "./PromotionBase"
import PromoIcon from "./PromoIcon"
import classes from "./Coupon.module.scss"
import { IAdvisor } from "../../../types"
import PromotionCouponIcon from "../../_common/QuesticoIcon/PromotionCouponIcon"

export interface ICouponBaseProps {
  isHeader?: boolean
  tooltipText?: string
  variation: TPromotionVariation
  expireAt: string | null
  advisor: IAdvisor | null
  withSwitcher?: boolean
  withExpiredAtSection?: boolean
}

type ICouponProps = ICouponBaseProps & IPromotionBaseRestProps

const CouponCircleIcon: React.FC<{
  dense: boolean
  isHeader: boolean | undefined
  withAdvisor: boolean
}> = ({ dense, isHeader, withAdvisor }) => {
  const className = clsx(classes.circle, {
    [classes.circleDetailed]: withAdvisor,
    [classes.circleRight]: !isHeader,
    [classes.circleDense]: dense,
    [classes.circleRightHeader]: isHeader,
  })

  return (
    <div className={className}>
      <div
        className={clsx(classes.circle, classes.circleRightMask, {
          [classes.circleDense]: dense,
        })}
      />
    </div>
  )
}

const ExpiredAtSection: React.FC<{
  expireAt: string
  isHeader: boolean
}> = ({ expireAt, isHeader }) => {
  return (
    <div className={classes.root}>
      <Typography
        color="textSecondary"
        component="span"
        className={clsx({
          [classes.expiresText]: !isHeader,
        })}
      >
        Gültig bis: {formatDate(expireAt)}
      </Typography>
      {isLessThenXDays(expireAt, 3) && (
        <Box component="span" className={classes.warnIcon}>
          <AlertsIcon />
        </Box>
      )}
    </div>
  )
}

const CouponBase: React.FC<ICouponProps> = ({
  variation,
  advisor,
  expireAt,
  testId,
  isHeader,
  tooltipText,
  rootClassName: _rootClassName,
  bodyClassName,
  children,
  withSwitcher,
  withExpiredAtSection,
}) => {
  const withAdvisor = !!advisor

  if (variation === "ribbon") {
    return null
  }

  if (variation === "minimal" || variation === "minimal-dense") {
    return (
      <PromotionBase
        variation={variation}
        iconPosition="end"
        renderIcon={(props) => <PromoIcon {...props} type="coupon" />}
        rootClassName={_rootClassName}
      >
        {children}
      </PromotionBase>
    )
  }

  const rootClassName = clsx(
    classes.rootCoupon,
    {
      [classes.rootCouponDense]: variation === "coupon-dense",
      [classes.rootCouponHeader]: isHeader,
      [classes.rootCouponHeaderDetailed]:
        isHeader && withAdvisor && expireAt !== null,
      [classes.rootCouponWithSwitcher]: withSwitcher,
    },
    _rootClassName
  )

  const couponBodyClassName = clsx(classes.couponBody, {
    [classes.couponBodyWide]: variation === "coupon",
  })

  return (
    <PromotionBaseRoot testId={testId} rootClassName={rootClassName}>
      <div className={classes.couponIconWrapper}>
        <PromotionCouponIcon className={classes.promotionCouponIcon} />
      </div>
      {!isHeader && (
        <Box className={couponBodyClassName}>
          <PromotionBaseBody
            variation={variation}
            bodyClassName={bodyClassName}
          >
            {children}
            {tooltipText && (
              <CustomTooltip
                iconButtonClassName={classes.tooltipIcon}
                tooltipText={tooltipText}
                iconNode={<InfoIcon width={18} height={18} />}
              />
            )}
          </PromotionBaseBody>
          {withAdvisor && variation === "coupon" && (
            <Typography className={classes.couponExpertName} component="div">
              Mit {advisor?.name}
            </Typography>
          )}
          {withExpiredAtSection && expireAt && formatDate(expireAt) && (
            <ExpiredAtSection expireAt={expireAt} isHeader={!!isHeader} />
          )}
        </Box>
      )}
      {isHeader && (
        <Box className={couponBodyClassName}>
          <PromotionBaseBody
            variation={variation}
            bodyClassName={clsx(classes.headerPromotionBaseBody, bodyClassName)}
          >
            {children}
            {tooltipText && (
              <CustomTooltip
                iconButtonClassName={classes.tooltipIcon}
                tooltipText={tooltipText}
                iconNode={<InfoIcon width={15} height={15} />}
              />
            )}
          </PromotionBaseBody>
          {withAdvisor && (
            <Typography className={classes.couponExpertName} component="div">
              Mit {advisor?.name}
            </Typography>
          )}
          {expireAt && formatDate(expireAt) && (
            <ExpiredAtSection expireAt={expireAt} isHeader={isHeader} />
          )}
        </Box>
      )}
      {!withSwitcher && (
        <CouponCircleIcon
          isHeader={isHeader}
          dense={variation === "coupon-dense"}
          withAdvisor={withAdvisor}
        />
      )}
    </PromotionBaseRoot>
  )
}

export default CouponBase
