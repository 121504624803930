import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const PromotionCouponIcon: React.FC<IIconProps> = ({ className }) => {
  const { isQuesticoBrand } = useCheckBrand()

  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg viewBox="0 0 77 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M77 70V0H0V19.6491C14.3573 24.8809 14.423 45.3031 0 50.3509V70H77Z"
            fill="#F76464"
          />
          <g clipPath="url(#clip0_25429_16375)">
            <path
              d="M29.594 20.653C30.4805 20.6507 31.3422 20.945 32.0422 21.489C32.7422 22.0329 33.2401 22.7954 33.4568 23.655C33.5979 24.1645 33.6334 24.6975 33.5613 25.2213C33.4892 25.7451 33.3109 26.2486 33.0374 26.7011C32.5013 27.6081 31.6286 28.2665 30.6093 28.5332C30.2776 28.6191 29.9366 28.6636 29.594 28.6656C28.5828 28.5905 27.6374 28.1358 26.9475 27.3928C26.2576 26.6497 25.8742 25.6733 25.8742 24.6593C25.8742 23.6454 26.2576 22.6689 26.9475 21.9258C27.6374 21.1828 28.5828 20.7281 29.594 20.653ZM29.594 17.342C28.9683 17.3467 28.3456 17.4282 27.7398 17.5848C26.0051 18.0208 24.4905 19.078 23.4832 20.556C22.4759 22.0341 22.0457 23.8303 22.2743 25.6043C22.5028 27.3783 23.3742 29.0069 24.7233 30.1813C26.0723 31.3558 27.8054 31.9946 29.594 31.9767C30.213 31.9818 30.8297 31.9 31.4261 31.7339C33.1588 31.2982 34.6718 30.2427 35.679 28.767C36.6862 27.2914 37.1178 25.4978 36.8922 23.7255C36.6666 21.9531 35.7994 20.3249 34.4546 19.1486C33.1098 17.9724 31.3806 17.3296 29.594 17.342Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M62 17.3412L24.3412 55L22 52.6588L59.6588 15L62 17.3412Z"
              fill="white"
            />
            <path
              d="M54.0299 42.1513C55.0227 42.1108 55.9949 42.4416 56.7569 43.0793C57.5189 43.7169 58.0159 44.6156 58.1511 45.6C58.2862 46.5843 58.0496 47.5837 57.4876 48.4031C56.9256 49.2225 56.0785 49.8031 55.1115 50.0315C54.7804 50.1203 54.4389 50.1648 54.0961 50.164C53.0849 50.0888 52.1396 49.6342 51.4497 48.8911C50.7598 48.148 50.3763 47.1716 50.3763 46.1576C50.3763 45.1437 50.7598 44.1672 51.4497 43.4242C52.1396 42.6811 53.0849 42.2264 54.0961 42.1513H54.0299ZM54.0961 38.8403C53.4697 38.8331 52.8454 38.9149 52.2419 39.0831C50.5092 39.5188 48.9962 40.5742 47.989 42.0499C46.9818 43.5256 46.5502 45.3191 46.7758 47.0915C47.0014 48.8638 47.8686 50.4921 49.2134 51.6683C50.5582 52.8446 52.2874 53.4873 54.074 53.475C54.6997 53.4703 55.3224 53.3887 55.9282 53.2322C57.6609 52.7965 59.1739 51.741 60.1812 50.2654C61.1884 48.7897 61.62 46.9961 61.3944 45.2238C61.1687 43.4514 60.3015 41.8232 58.9567 40.6469C57.6119 39.4707 55.8827 38.8279 54.0961 38.8403Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_25429_16375">
              <rect
                width="40"
                height="40"
                fill="white"
                transform="translate(22 15)"
              />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg viewBox="0 0 77 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M77 70V0H0V19.6491C14.3573 24.8809 14.423 45.3031 0 50.3509V70H77Z"
            fill="#FF7E9D"
          />
          <path
            d="M48.8056 21.3889C48.0278 21.5833 47.25 21.3889 46.8611 20.6111C46.0833 18.8611 44.1389 17.5 42 17.5C39.8611 17.5 37.9167 18.8611 37.1389 20.6111C36.75 21.3889 35.9722 21.7778 35.1944 21.3889C33.25 20.6111 31.1111 21.1944 29.5556 22.5556C28 23.9167 27.6111 26.25 28.3889 28.1945C28.5833 28.9722 28.3889 29.75 27.6111 30.1389C25.8611 30.9167 24.5 32.8611 24.5 35C24.5 37.1389 25.8611 39.0833 27.6111 39.8611C28.3889 40.25 28.7778 41.0278 28.3889 41.8056C27.6111 43.75 28.1944 45.8889 29.5556 47.4444C30.9167 49 33.25 49.3889 35.1944 48.6111C35.9722 48.4167 36.75 48.6111 37.1389 49.3889C37.9167 51.1389 39.8611 52.5 42 52.5C44.1389 52.5 46.0833 51.1389 46.8611 49.3889C47.25 48.6111 48.0278 48.2222 48.8056 48.6111C50.75 49.3889 52.8889 48.8056 54.4444 47.4444C56 46.0833 56.3889 43.75 55.6111 41.8056C55.4167 41.0278 55.6111 40.25 56.3889 39.8611C58.1389 39.0833 59.5 37.1389 59.5 35C59.5 32.8611 58.1389 30.9167 56.3889 30.1389C55.6111 29.75 55.2222 28.9722 55.6111 28.1945C56.3889 26.25 55.8055 24.1111 54.4444 22.5556C53.0833 21 50.5556 20.6111 48.8056 21.3889Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M36.7498 32.8616C38.468 32.8616 39.8609 31.4687 39.8609 29.7505C39.8609 28.0323 38.468 26.6394 36.7498 26.6394C35.0316 26.6394 33.6387 28.0323 33.6387 29.7505C33.6387 31.4687 35.0316 32.8616 36.7498 32.8616Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M47.2498 43.3622C48.968 43.3622 50.3609 41.9693 50.3609 40.2511C50.3609 38.5329 48.968 37.14 47.2498 37.14C45.5316 37.14 44.1387 38.5329 44.1387 40.2511C44.1387 41.9693 45.5316 43.3622 47.2498 43.3622Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M47.2489 29.7509L35.1934 41.8064"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default PromotionCouponIcon
