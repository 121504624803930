import * as Sentry from "@sentry/nextjs";

import { assertIsDefined } from "../../../utils/utils"
import {
  LOCAL_STORAGE_KEYS,
  ICustomerProfile,
  DeepPartial,
  ICustomerBalance,
} from "../../../types"
import { storage } from "../../../utils/localStorage"
import {
  USER_ACTION_TYPE,
  IUserAction,
  IInitialCustomerDataPayload,
  ICustomerDataPayload,
  IPreferenceGroupData,
  IFetchPreferencesResult,
} from "../types"
import { IProductFlowDataOrNull } from "../../../ui-lib/CallFlow/CallFlowService/types"
import { IDefaultRequestConfig } from "../../../utils/rest"

function makeActions(
  dispatch: React.Dispatch<IUserAction>,
  trigger: <T>(extraArgument: IDefaultRequestConfig) => Promise<T>
) {
  async function fetchUserData() {
    try {
      const user = await trigger<ICustomerProfile>({ url: "me" })

      const payload: ICustomerDataPayload = {
        details: user,
        errors: {},
      }

      dispatch({
        type: USER_ACTION_TYPE.UPDATE_USER_DETAILS,
        payload,
      })
    } catch (e) {
      console.error(e)
    }
  }

  // token supposed to be provided from query string
  async function verifyEmail(token: string | null | undefined, userId: string) {
    if (!token || !userId) {
      dispatch({
        type: USER_ACTION_TYPE.VERIFY_EMAIL_ERROR,
        payload: null,
      })
      return
    }

    try {
      // verify email api call
      if (true) {
        await fetchUserData()

        dispatch({
          type: USER_ACTION_TYPE.VERIFY_EMAIL_SUCCESS,
          payload: null,
        })
      }
    } catch (e) {
      dispatch({
        type: USER_ACTION_TYPE.VERIFY_EMAIL_ERROR,
        payload: null,
      })
    }
  }

  async function resendVerificationEmail(_userId: string) {
    try {
      const data = true
      // resend verification email api call
      if (data) {
        dispatch({
          type: USER_ACTION_TYPE.SEND_VERIFICATION_EMAIL_SUCCESS,
        })
      }
    } catch (e) {
      dispatch({
        type: USER_ACTION_TYPE.SEND_VERIFICATION_EMAIL_SUCCESS,
      })
    }
  }

  function resetSendVerificationEmailData() {
    dispatch({
      type: USER_ACTION_TYPE.RESET_SEND_VERIFICATION_EMAIL_DATA,
    })
  }

  /**
   * fetches user data, promotions, balance
   */
  async function fetchInitialUserData(newToken: string) {
    assertIsDefined(newToken)

    try {
      const user = await trigger<ICustomerProfile>({ url: "me" })

      Sentry.setUser({ id: user.id, username: user.login, email: user.email });

      const payload: IInitialCustomerDataPayload = {
        initialLoadReady: true,
        user: {
          details: user,
          errors: {},
        },
      }

      dispatch({
        type: USER_ACTION_TYPE.SET_INITIAL_USER_DATA,
        payload,
      })
    } catch (e) {
      console.error(e)
    }
  }

  function resetUserState() {
    Sentry.setUser(null)

    dispatch({
      type: USER_ACTION_TYPE.RESET_USER_STATE,
    })
  }

  async function fetchPreferences(token?: string) {
    dispatch({
      type: USER_ACTION_TYPE.PREFERENCES_LOADING,
      payload: {
        loading: true,
      },
    })

    try {
      const url = token
        ? `notification-preferences-by-token/${token}`
        : "notification-preferences"

      const data = await trigger<IFetchPreferencesResult>({ url })

      const groups = data?.groups || []

      dispatch({
        type: USER_ACTION_TYPE.SET_PREFERENCES,
        payload: {
          groups,
          loading: false,
        },
      })

      return { groups }
    } catch (e) {
      console.error(e)

      if (e instanceof Error) {
        dispatch({
          type: USER_ACTION_TYPE.GET_PREFERENCES_ERROR,
          payload: e,
        })
      }

      dispatch({
        type: USER_ACTION_TYPE.PREFERENCES_LOADING,
        payload: {
          loading: false,
        },
      })

      return { groups: [] }
    }
  }

  async function updatePreferenceGroups(
    nextGroups: IPreferenceGroupData[],
    token?: string
  ) {
    try {
      const preferenceValues = nextGroups.reduce(
        (acc: Record<string, boolean>, group) => {
          group.items.forEach((groupItem) => {
            acc[groupItem.name] = groupItem.enabled
          })

          return acc
        },
        {}
      )

      const url = token
        ? `notification-preferences-by-token/${token}`
        : "notification-preferences"

      const data = await trigger({
        url,
        method: "PATCH",
        data: preferenceValues,
      })

      if (data) {
        dispatch({
          type: USER_ACTION_TYPE.UPDATE_PREFERENCE_GROUPS,
          payload: nextGroups,
        })
      }
    } catch (e) {
      if (e instanceof Error) {
        dispatch({
          type: USER_ACTION_TYPE.UPDATE_PREFERENCES_ERROR,
          payload: e,
        })
        throw new Error(e.toString())
      }
    }
  }

  function setUserBalance(balance: ICustomerBalance) {
    dispatch({
      type: USER_ACTION_TYPE.UPDATE_USER_BALANCE,
      payload: balance,
    })
  }

  async function updateUserDetails(requestData: DeepPartial<ICustomerProfile>) {
    try {
      const data = await trigger<ICustomerProfile | undefined>({
        url: "me",
        method: "PATCH",
        data: requestData,
      })
      if (!data) throw new Error("no data")

      dispatch({
        type: USER_ACTION_TYPE.UPDATE_USER_DETAILS,
        payload: {
          details: data,
        },
      })

      return data
    } catch (e) {
      if (e instanceof Error) {
        dispatch({
          type: requestData.email
            ? USER_ACTION_TYPE.UPDATE_USER_EMAIL_ERROR
            : USER_ACTION_TYPE.UPDATE_USER_DETAILS_ERROR,
          payload: [e],
        })
        throw new Error(e.toString())
      }
    }
  }

  function resetUserDetailsErrors() {
    dispatch({
      type: USER_ACTION_TYPE.UPDATE_USER_DETAILS,
    })
  }

  async function setProductFlowSettings(
    productFlowSettings: IProductFlowDataOrNull
  ) {
    if (productFlowSettings === null) {
      storage.cleanUp(LOCAL_STORAGE_KEYS.PRODUCT_FLOW_SETTINGS)

      dispatch({
        type: USER_ACTION_TYPE.RESET_PRODUCT_FLOW_SETTINGS,
      })
    } else {
      if (typeof window !== "undefined") {
        storage.setItem(
          LOCAL_STORAGE_KEYS.PRODUCT_FLOW_SETTINGS,
          JSON.stringify(productFlowSettings)
        )
      }

      dispatch({
        type: USER_ACTION_TYPE.SET_PRODUCT_FLOW_SETTINGS,
        payload: productFlowSettings,
      })
    }
  }

  return {
    resetUserState,
    fetchInitialUserData,
    fetchUserData,
    updateUserDetails,
    setUserBalance,
    verifyEmail,
    resendVerificationEmail,
    resetSendVerificationEmailData,
    fetchPreferences,
    updatePreferenceGroups,
    resetUserDetailsErrors,
    setProductFlowSettings,
  }
}

export default makeActions
