import React from "react"
import clsx from "clsx"

import { Box, ProductPriceString, Typography } from "../_common"
import { IAdvisorProduct, IPromotion, IPromotionAdvisor } from "../../types"
import { TPromotionVariation } from "../Promotions/types"
import PromotionVariation from "../Promotions/PromotionVariation"
import { getCheapestProduct, hasFreeProductPromotion } from "./Products/utils"
import classes from "./ProductPrice.module.scss"

interface IProductPriceProps {
  products: IAdvisorProduct[]
  promotion: IPromotion | IPromotionAdvisor | null
  /**
   * overrides default `minimal` value
   */
  promotionVariation?: TPromotionVariation
  /** show only promotions without price, need for My questico dashboard */
  forcedHidePrice?: boolean
  rootClassName?: string
  /** `false` by default. Centers price and promo horizontally */
  centered?: boolean
  /** `false` by default. Allow promo body to break into new line on screens < 375px */
  promoBreakLine?: boolean
  priceTooltip?: React.ReactNode
  useDefaultPromotionText?: boolean
}

const ProductPrice: React.FC<IProductPriceProps> = ({
  products,
  promotion,
  rootClassName: _rootClassName,
  promotionVariation = "ribbon",
  forcedHidePrice = false,
  centered = false,
  promoBreakLine = false,
  priceTooltip = null,
  useDefaultPromotionText,
}) => {
  const cheapestProduct = getCheapestProduct(products)

  if (!cheapestProduct) {
    return null
  }

  const { withPrefix, price, priceSale } = cheapestProduct
  const hasFreePromotions = hasFreeProductPromotion(promotion)

  const shouldRenderPrice = !hasFreePromotions && !forcedHidePrice

  const rootClassName = clsx(classes.root, _rootClassName, {
    [classes.rootCentered]: centered,
  })

  const priceClassName = clsx(classes.priceWrapper, {
    [classes.priceWithPromo]: shouldRenderPrice,
    [classes.priceWithPromoCentered]: shouldRenderPrice && centered,
  })

  const promotionBodyClassName = clsx({
    [classes.promotionBodyLineBreak]: promoBreakLine,
  })

  return (
    <Box className={rootClassName} data-testid="product-price">
      {shouldRenderPrice && (
        <Typography component="span" className={priceClassName}>
          <ProductPriceString
            price={price}
            priceSale={priceSale}
            allowHighlight
            withPrefix={withPrefix}
          />
          {priceTooltip}
        </Typography>
      )}

      <PromotionVariation
        variation={promotionVariation}
        promotion={promotion}
        rootClassName={classes.promotionRoot}
        bodyClassName={promotionBodyClassName}
        useDefaultPromotionText={useDefaultPromotionText}
      />
    </Box>
  )
}

export default ProductPrice
