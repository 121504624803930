import React from "react"
import clsx from "clsx"

import GiftIcon from "../../_common/QuesticoIcon/GiftIcon"
import CouponIcon from "../../_common/QuesticoIcon/CouponIcon"
import { IPromoIconProps } from "../types"
import classes from "./PromoIcon.module.scss"

const PromoIcon: React.FC<IPromoIconProps> = ({
  iconPosition,
  isDense,
  type,
}) => {
  const iconWrapperClassName = clsx(classes.iconWrapper, {
    [classes.iconWrapperLeft]: iconPosition === "start",
    [classes.iconWrapperRight]: iconPosition === "end",
    [classes.iconDense]: isDense,
  })

  switch (type) {
    case "coupon":
      return <CouponIcon className={iconWrapperClassName} />
    case "gift":
    default:
      return <GiftIcon className={iconWrapperClassName} />
  }
}

export default PromoIcon
