import { storage } from "../../../utils/localStorage"
import { LOCAL_STORAGE_KEYS } from "../../../types"
import { IProductFlowDataOrNull } from "../../../ui-lib/CallFlow/CallFlowService/types"
import { UserState } from "../types"
import { IServiceFeedbackData } from "../../../ui-lib/ProductFlow/ServiceFeedback/types"
import { buildDate } from "../../../ui-lib/MyQuestico/AccountInfo/utils"

function getPersistedValue<T>(key: LOCAL_STORAGE_KEYS) {
  if (typeof window === "undefined") return null

  const rawValue = storage.getItem(key)
  try {
    return rawValue !== null ? (JSON.parse(rawValue) as T) : null
  } catch (e) {
    return null
  }
}

function makeSelectors(userState: UserState) {
  function getProductFlowSettings() {
    return (
      userState.productFlowSettings ||
      getPersistedValue<IProductFlowDataOrNull>(
        LOCAL_STORAGE_KEYS.PRODUCT_FLOW_SETTINGS
      )
    )
  }

  function getServiceFeedbackCurData() {
    const data = getPersistedValue<IServiceFeedbackData>(
      LOCAL_STORAGE_KEYS.RATING_SUCCESS_SECTION_CUR_DATA
    )

    return {
      consultationId: data?.consultationId,
      step: data?.step,
      product: data?.product,
    }
  }

  function isPhoneVerified() {
    /**
     * backend is not ready yet for phone verification.
     * we assume phone is verified.
     */
    return true
  }

  function getPhoneNumber() {
    const { details } = userState.user
    const mainPhone = details.phones?.find((phone) => phone.is_main)
    return {
      phoneNumber: mainPhone && `+${mainPhone.code}${mainPhone.number}`,
      id: mainPhone?.id,
    }
  }

  function getFormattedBirthdate() {
    const { details } = userState.user

    const {
      birthday_year: birthdayYear,
      birthday_month: birthdayMonth,
      birthday_day: birthdayDay,
    } = details.personal || {
      birthday_year: null,
      birthday_month: null,
      birthday_day: null,
    }

    return buildDate({ birthdayYear, birthdayMonth, birthdayDay })
  }

  /**
   * @see {@link https://adviqo.atlassian.net/browse/MISSION-2793?focusedCommentId=170596}
   */
  function isEmailVerified() {
    const { details } = userState.user

    if (details?.email === undefined) return false

    return Boolean(details.email)
  }

  // it'd be better called something like isDOIEnforced
  function isEmailVerificationRequired() {
    /**
     * @see https://adviqo.atlassian.net/browse/FEM-286
     */
    return false
    // const { details } = userState.user

    // if (details?.email) {
    //   return details.emailVerificationRequired
    // }
  }

  return {
    getProductFlowSettings,
    isPhoneVerified,
    getPhoneNumber,
    getFormattedBirthdate,
    isEmailVerified,
    isEmailVerificationRequired,
    getServiceFeedbackCurData,
  }
}

export default makeSelectors

export type TMakeSelectorsResult = ReturnType<typeof makeSelectors>
