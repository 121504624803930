import React from "react"
import { useRouter } from "next/navigation"
import { ParsedUrlQueryInput } from "querystring"
import clsx from "clsx"

import { Box, useTheme } from "../../_common"
import { ProductButton } from "../../_common/Buttons"
import { useProductService } from "../../../hooks/productService"
import { isProductFree, isReservation } from "./utils"
import { useRuntimeConfig } from "../../../hooks/runtimeConfig"
import {
  IListingNumber,
  ProductFlowStep,
  LOCAL_STORAGE_KEYS,
  IAdvisorProduct,
  IAdvisorStatus,
  PageSlug,
  ICheckoutFlowParams,
  IPromotion,
  IAdvisorProductType,
} from "../../../types"
import useProductTracking from "./hooks/useProductTracking"
import ProductPriceString from "../../_common/ProductPriceString"
import { storage } from "../../../utils/localStorage"
import { updateSearchParamsFromEntries } from "../../../utils/utils"
import classes from "./ProductsList.module.scss"

interface IProductsListProps {
  products: IAdvisorProduct[]
  promotion: IPromotion | null
  listingNo: IListingNumber
  /** Expert data */
  photo?: string
  displayName: string
  rootClassName?: string
  itemClassName?: string
  withLabel?: boolean
  withPrice?: boolean
  initSrc?: string
  advisorStatus: IAdvisorStatus
  getClientTrackingProperties?: () => Record<string, any>
  stepToSkip?: ProductFlowStep
  customProductsLabels?: Partial<Record<IAdvisorProductType, string>>
  onProductClick?: () => void
}

const ProductsList: React.FC<IProductsListProps> = ({
  products,
  promotion,
  listingNo,
  photo,
  displayName,
  rootClassName,
  itemClassName,
  withLabel = true,
  withPrice = false,
  initSrc,
  advisorStatus,
  stepToSkip,
  customProductsLabels,
  onProductClick: onProductClickCallback,
}) => {
  const router = useRouter()
  const theme = useTheme()

  const { getRuntimeConfig } = useRuntimeConfig()

  const { productIsActive } = useProductService()
  const { trackProductEvent } = useProductTracking(
    listingNo,
    displayName,
    advisorStatus
  )

  const onProductClick = (
    product: IAdvisorProduct,
    advisorStatus: IAdvisorStatus,
    isFreeProduct: boolean
  ) => {
    const isCallback = isReservation(product, advisorStatus)

    const query: ICheckoutFlowParams & ParsedUrlQueryInput = {
      listingNo,
      // just a hack
      isFree: isFreeProduct,
      productType: product.type,
      photo,
      displayName,
      price: product.price,
      priceSale: product.price_sale,
      initSrc,
      ...(stepToSkip && { stepToSkip }),
    }

    storage.cleanUp(LOCAL_STORAGE_KEYS.RATING_SUCCESS_SECTION_CUR_DATA)

    trackProductEvent(product, isCallback)
    const newParams = updateSearchParamsFromEntries(undefined, query)

    router.push(`/${PageSlug.CHECKOUT}?${newParams}`)

    if (onProductClickCallback) {
      onProductClickCallback()
    }
  }

  return (
    <Box component="ul" className={clsx(classes.list, rootClassName)}>
      {products.map((product) => {
        const isFreeProduct = isProductFree(
          product.type,
          getRuntimeConfig,
          promotion
        )

        return (
          <Box
            key={product.type}
            component="li"
            className={clsx(classes.item, itemClassName, {
              [classes.withPrice]: withPrice,
            })}
          >
            {withPrice && product && (
              <div>
                <ProductPriceString
                  price={product.price}
                  priceSale={isFreeProduct ? product.price_sale : null}
                  allowHighlight
                  priceTextColor={
                    product.price === 0
                      ? theme.palette.secondary.main
                      : undefined
                  }
                />
              </div>
            )}

            <ProductButton
              isFreeProduct={isFreeProduct}
              withLabel={withLabel}
              productType={product.type}
              available={product.is_available}
              advisorStatus={advisorStatus}
              productIsActive={productIsActive}
              testId={product.type === "call" ? "callButton" : "chatButton"}
              onClick={() => {
                onProductClick(product, advisorStatus, isFreeProduct)
              }}
              {...(customProductsLabels?.[product.type] && {
                labelText: customProductsLabels[product.type],
              })}
              className={classes.productButton}
            />
          </Box>
        )
      })}
    </Box>
  )
}

export default ProductsList
