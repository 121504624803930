import { ICustomerBalance } from "../../../types"
import { IProductFlowDataOrNull } from "../../../ui-lib/CallFlow/CallFlowService/types"
import {
  USER_ACTION_TYPE,
  UserState,
  IUserAction,
  IInitialCustomerDataPayload,
  IDataLoadingPayload,
  ICustomerDataPayload,
  IPreferencesData,
  IPreferenceGroupData,
} from "../types"
import { defaultUserState } from "./defaultUserState"
import { getCodeAndMessageFromApiError } from "../../../utils/rest"

function reducer(state: UserState, action: IUserAction): UserState {
  switch (action.type) {
    case USER_ACTION_TYPE.SET_INITIAL_USER_DATA: {
      const { user, initialLoadReady } = {
        ...(action.payload as IInitialCustomerDataPayload),
      }

      return {
        ...state,
        initialLoadReady,
        user: {
          ...state.user,
          details: {
            ...state.user?.details,
            ...user?.details,
          },
          errors: user?.errors,
        },
      }
    }

    case USER_ACTION_TYPE.RESET_USER_STATE: {
      return defaultUserState
    }

    case USER_ACTION_TYPE.SET_PREFERENCES: {
      return {
        ...state,
        preferencesData: action.payload as IPreferencesData,
      }
    }

    case USER_ACTION_TYPE.UPDATE_USER_DETAILS: {
      return {
        ...state,
        user: {
          ...state.user,
          errors: undefined,
          details: {
            ...state.user?.details,
            ...(action.payload &&
              (action.payload as Partial<ICustomerDataPayload>).details),
          },
        },
      }
    }

    case USER_ACTION_TYPE.UPDATE_PREFERENCE_GROUPS: {
      return {
        ...state,
        preferencesData: {
          ...state.preferencesData,
          error: undefined,
          groups: action.payload as IPreferenceGroupData[],
        },
      }
    }

    case USER_ACTION_TYPE.PREFERENCES_LOADING: {
      const nextState: UserState = {
        ...state,
        preferencesData: {
          ...state.preferencesData,
          loading: (action.payload as IDataLoadingPayload).loading,
        },
      }
      return nextState
    }

    case USER_ACTION_TYPE.UPDATE_PREFERENCES_ERROR: {
      const nextState = {
        ...state,
        preferencesData: {
          ...state.preferencesData,
          error: getCodeAndMessageFromApiError(action.payload as any).message,
        },
      }
      return nextState
    }

    case USER_ACTION_TYPE.GET_PREFERENCES_ERROR: {
      const nextState = {
        ...state,
        preferencesData: {
          ...state.preferencesData,
          error: getCodeAndMessageFromApiError(action.payload as any).message,
        },
      }
      return nextState
    }

    case USER_ACTION_TYPE.UPDATE_USER_EMAIL_ERROR: {
      const nextState = {
        ...state,
        user: {
          ...state.user,
          errors: {
            email: action.payload as Array<Error>,
          },
        },
      }
      return nextState
    }

    case USER_ACTION_TYPE.UPDATE_USER_DETAILS_ERROR: {
      const nextState = {
        ...state,
        user: {
          ...state.user,
          errors: action.payload as { [key: string]: Readonly<Error[]> },
        },
      }
      return nextState
    }

    case USER_ACTION_TYPE.UPDATE_USER_BALANCE: {
      return {
        ...state,
        user: {
          ...state.user,
          details: {
            ...state.user.details,
            balance: action.payload as ICustomerBalance,
          },
        },
      }
    }

    case USER_ACTION_TYPE.VERIFY_EMAIL_SUCCESS: {
      const nextState = {
        ...state,
        verifyEmail: {
          ...state.verifyEmail,
          loading: false,
          error: undefined,
          result: true,
        },
      }
      return nextState
    }

    case USER_ACTION_TYPE.VERIFY_EMAIL_ERROR: {
      const nextState = {
        ...state,
        verifyEmail: {
          ...state.verifyEmail,
          loading: false,
          error: action.payload as string,
          result: false,
        },
      }
      return nextState
    }

    case USER_ACTION_TYPE.SEND_VERIFICATION_EMAIL_SUCCESS: {
      const nextState = {
        ...state,
        resendVerificationEmail: {
          ...state.resendVerificationEmail,
          loading: false,
          result: true,
        },
      }
      return nextState
    }

    case USER_ACTION_TYPE.RESET_SEND_VERIFICATION_EMAIL_DATA: {
      const nextState = {
        ...state,
        resendVerificationEmail: {
          loading: undefined,
          error: undefined,
          result: undefined,
        },
      }
      return nextState
    }

    case USER_ACTION_TYPE.SET_PRODUCT_FLOW_SETTINGS: {
      return {
        ...state,
        productFlowSettings: {
          ...state.productFlowSettings,
          ...(action.payload as IProductFlowDataOrNull),
        },
      }
    }

    case USER_ACTION_TYPE.RESET_PRODUCT_FLOW_SETTINGS: {
      return { ...state, productFlowSettings: null }
    }
    default:
      return state
  }
}

export default reducer
