import React from "react"
import clsx from "clsx"

import { Typography, Box } from "../../_common/MaterialUI"
import { IPromotionBaseProps } from "../types"
import classes from "./PromotionBase.module.scss"

export const PromotionBaseRoot: React.FC<Pick<
  IPromotionBaseProps,
  "rootClassName" | "testId" | "children"
>> = ({ rootClassName: _rootClassName, children, testId }) => {
  const rootClassName = clsx(classes.root, _rootClassName)

  return (
    <Box data-testid={testId} className={rootClassName}>
      {children}
    </Box>
  )
}

export const PromotionBaseBody: React.FC<Pick<
  IPromotionBaseProps,
  "bodyClassName" | "variation" | "children"
>> = ({ bodyClassName: _bodyClassName, children, variation }) => {
  const bodyClassName = clsx(
    classes.body,
    { [classes.bodyDense]: variation === "minimal-dense" },
    _bodyClassName
  )

  return (
    <Typography component="span" className={bodyClassName}>
      {children}
    </Typography>
  )
}

const PromotionBase: React.FC<IPromotionBaseProps> = ({
  children,
  iconPosition = "end",
  rootClassName: _rootClassName,
  bodyClassName,
  variation,
  renderIcon,
  testId,
}) => {
  const icon = renderIcon({
    isDense: variation === "minimal-dense",
    iconPosition,
  })
  const rootClassName = clsx(
    classes.root,
    { [classes.rootDense]: variation === "minimal-dense" },
    _rootClassName
  )

  return (
    <PromotionBaseRoot testId={testId} rootClassName={rootClassName}>
      {iconPosition === "start" && icon}
      <PromotionBaseBody bodyClassName={bodyClassName} variation={variation}>
        {children}
      </PromotionBaseBody>
      {iconPosition === "end" && icon}
    </PromotionBaseRoot>
  )
}

export default PromotionBase
