"use client"

import React, { useCallback, useState } from "react"
import { useRouter } from "next/navigation"
import { AxiosError } from "axios"
import { useUser } from "../user"
import useInterval from "../useInterval"
import { CallStatusType, PageSlug, ProductFlowStep } from "../../types"
import ProductServiceContext from "./ProductServiceContext"
import { IProductServiceContext } from "./types"
import useCallApi from "../../ui-lib/CallFlow/CallFlowService/useCallApi"
import useChatApi from "../../ui-lib/ChatFlow/ChatFlowService/useChatApi"
import sentryUtils from "../../utils/sentryUtils"

const POLL_INTERVAL = 5000

const ProductServiceProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { push } = useRouter()

  const { setProductFlowSettings, getProductFlowSettings } = useUser()

  const {
    getActiveCallSession: _getActiveCallSession,
    activeCallSessionError,
    activeCallSessionData,
  } = useCallApi()

  const { getActiveChatSession: _getActiveChatSession } = useChatApi()

  const { sessionId, listingNumber, isFree, avatar, productType } =
    getProductFlowSettings() || {}

  const [shouldShowRatingReminder, setShouldShowRatingReminder] = useState(
    false
  )

  const showRatingReminder = useCallback(() => {
    setShouldShowRatingReminder(true)
  }, [])

  const goToStep = useCallback(() => {
    let step

    switch (productType) {
      case "call": {
        step =
          activeCallSessionData?.details.status === CallStatusType.CREATED
            ? ProductFlowStep.connecting
            : ProductFlowStep.calling
        break
      }

      case "chat": {
        step = ProductFlowStep.chatting
        break
      }

      default: {
        /**
         * Default ProductFlowStep.failed step.
         * Potentially this step will NEVER be shown,
         * just a potential fallback for any case.
         */
        step = ProductFlowStep.failed
        break
      }
    }
    const path =
      productType === "call" ? `/${PageSlug.CALL}` : `/${PageSlug.CHAT}`
    const searchParams = new URLSearchParams({
      ...(listingNumber && { listingNo: listingNumber }),
      ...(productType && { productType }),
      ...(typeof isFree !== "undefined" && { isFree: isFree.toString() }),
      step,
    })

    push(`${path}?${searchParams}`)
  }, [
    activeCallSessionData?.details.status,
    isFree,
    listingNumber,
    productType,
    push
  ])

  const resetProductFlowSettings = useCallback(
    (error: AxiosError) => {
      /**
       * Check for "fake" http error, reproduced only in Safari.
       *
       * https://stackoverflow.com/questions/19858251/http-status-code-0-error-domain-nsurlerrordomain
       */
      if (error.response?.status !== 0) {
        setProductFlowSettings(null)
      }
    },
    [setProductFlowSettings]
  )

  const getActiveCallSession = useCallback(async () => {
    try {
      const response = await _getActiveCallSession({ id: sessionId! })

      if (response) {
        const {
          details: { status },
        } = response

        const shouldPollStatus =
          status === CallStatusType.CREATED ||
          status === CallStatusType.IN_PROGRESS

        if (!shouldPollStatus) {
          setProductFlowSettings(null)
        }
      } else {
        setProductFlowSettings(null)
      }
    } catch (error) {
      resetProductFlowSettings(error as AxiosError)

      sentryUtils.report({ error })
    }
  }, [
    _getActiveCallSession,
    resetProductFlowSettings,
    sessionId,
    setProductFlowSettings,
  ])

  const getActiveChatSession = useCallback(async () => {
    try {
      await _getActiveChatSession()
    } catch (error) {
      resetProductFlowSettings(error as AxiosError)

      /** 404 status means we don't have active session any more
       * and we can reset storage data.
       * Otherwise its request error and we want to track it.
       */
      if ((error as AxiosError).response?.status !== 404) {
        sentryUtils.report({ error })
      }
    }
  }, [_getActiveChatSession, resetProductFlowSettings])

  const productIsActive = Boolean(sessionId)

  useInterval(
    getActiveCallSession,
    productType === "call" && productIsActive ? POLL_INTERVAL : null,
    true
  )

  useInterval(
    getActiveChatSession,
    productType === "chat" && productIsActive ? POLL_INTERVAL : null,
    true
  )

  const value: IProductServiceContext = {
    activeCallSessionData,
    error: activeCallSessionError,
    productIsActive,
    goToStep,
    avatar,
    productType,
    shouldShowRatingReminder,
    showRatingReminder,
  }

  return (
    <ProductServiceContext.Provider value={value}>
      {children}
    </ProductServiceContext.Provider>
  )
}

export default ProductServiceProvider
