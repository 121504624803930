import useSWRMutation from "swr/mutation"

import { IChatDetails } from "../../../types"
import { IStartChatSessionPayload } from "./types"
import { fetchWithConfig, IFetchWithConfigOptions } from "../../../utils/rest"
import { getRuntimeConfig } from "../../../utils/runtimeConfig"

const useChatApi = () => {
  const {
    trigger: startChatSession,
    error: startChatSessionError,
    isMutating: startChatSessionLoading,
    data: chatSessionData,
  } = useSWRMutation(
    "chat/start",
    (
      url,
      { arg, headers }: IFetchWithConfigOptions<IStartChatSessionPayload>
    ) => {
      return fetchWithConfig<{
        details: IChatDetails
      }>(
        {
          url,
          data: arg,
          method: "POST",
          headers,
        },
        getRuntimeConfig
      )
    }
  )

  const {
    trigger: getActiveChatSession,
    isMutating: activeChatSessionLoading,
    error: activeChatSessionError,
    data: activeChatSessionData,
  } = useSWRMutation(
    "chat",
    (url, { headers }: IFetchWithConfigOptions<unknown>) => {
      return fetchWithConfig<{
        details: IChatDetails
      }>(
        {
          url,
          method: "GET",
          headers,
        },
        getRuntimeConfig
      )
    }
  )

  const { trigger: getChatSessionById } = useSWRMutation(
    "chat",
    (
      url,
      { arg: { id }, headers }: IFetchWithConfigOptions<{ id: number }>
    ) => {
      return fetchWithConfig<{
        details: IChatDetails
      }>(
        {
          url,
          method: "POST",
          data: {
            id,
          },
          headers,
        },
        getRuntimeConfig
      )
    }
  )

  return {
    startChatSession,
    startChatSessionError,
    startChatSessionLoading,
    chatSessionData,
    getActiveChatSession,
    activeChatSessionLoading,
    activeChatSessionError,
    activeChatSessionData,
    getChatSessionById,
  }
}

export default useChatApi
