import React from "react"

import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const GiftIcon: React.FC<IIconProps> = ({ className }) => {
  const { isQuesticoBrand } = useCheckBrand()

  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg height="100%" width="100%" fill="none" viewBox="0 0 21 18">
          <path
            stroke="currentColor"
            strokeWidth="1.5"
            d="M2.288 12.219v5.237h16.687V12.22M12.4 9.625h7.862V6.056H1v3.569h7.894M8.944 6.144v11.262M12.319 6.144v11.262"
          />
          <path
            stroke="currentColor"
            strokeWidth="1.5"
            d="M10.637 6c.463-3.125 1.794-5 3.563-5 1.401 0 2.538 1.136 2.538 2.538 0 1.4-1.137 2.537-2.538 2.537M10.619 6c-.457-3.125-1.794-5-3.557-5-1.401 0-2.537 1.136-2.537 2.538 0 1.4 1.136 2.537 2.537 2.537"
          />
        </svg>
      ) : (
        <svg height="100%" width="100%" fill="none" viewBox="0 0 18 18">
          <path
            d="M14.9562 8.57897H3.08336C2.36136 8.57897 1.7998 8.01742 1.7998 7.29542V5.85142C1.7998 5.12942 2.36136 4.56787 3.08336 4.56787H14.9562C15.6782 4.56787 16.2398 5.12942 16.2398 5.85142V7.29542C16.2398 8.01742 15.6782 8.57897 14.9562 8.57897Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.6352 8.98047V13.7938C14.6352 15.0773 13.7528 16.2004 12.6297 16.2004H5.48992C4.36682 16.2004 3.48438 15.1576 3.48438 13.7938V8.98047"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.61864 4.16684C8.13731 4.64817 7.01421 4.24706 6.53287 3.84595C6.05154 3.36462 6.05154 2.64262 6.53287 2.16129C7.01421 1.67996 7.7362 1.67996 8.21753 2.16129C8.69887 2.64262 9.09998 3.76573 8.61864 4.16684Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.42138 4.16684C9.90272 4.64817 11.0258 4.24706 11.5072 3.84595C11.9885 3.36462 11.9885 2.64262 11.5072 2.16129C11.0258 1.67996 10.3038 1.67996 9.82249 2.16129C9.34116 2.64262 8.94005 3.76573 9.42138 4.16684Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default GiftIcon
