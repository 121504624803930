import { createContext } from "react"

import { ICustomerProfile } from "../../types"
import { TUseUserReducerHookResult } from "./useUserReducer"
import { defaultUserState } from "./state/defaultUserState"

export const defaultContextValue: TUseUserReducerHookResult = {
  state: defaultUserState,
  setUserBalance: () => ({}),
  resetUserState: () => null,
  fetchInitialUserData: () => Promise.resolve(),
  fetchUserData: () => Promise.resolve(),
  updateUserDetails: () => new Promise<ICustomerProfile>(() => ({})),
  setProductFlowSettings: () => Promise.resolve(),
  getProductFlowSettings: () => null,
  verifyEmail: () => Promise.resolve(),
  resendVerificationEmail: () => Promise.resolve(),
  resetSendVerificationEmailData: () => null,
  isPhoneVerified: () => false,
  getPhoneNumber: () => ({ id: undefined, phoneNumber: undefined }),
  getFormattedBirthdate: () => "",
  fetchPreferences: () => Promise.resolve({ groups: [], preferences: [] }),
  updatePreferenceGroups: () => Promise.resolve(),
  isEmailVerified: () => true,
  isEmailVerificationRequired: () => false,
  getServiceFeedbackCurData: () => ({
    consultationId: undefined,
    step: undefined,
    product: undefined,
  }),
  resetUserDetailsErrors: () => undefined,
}

const UserContext = createContext<TUseUserReducerHookResult>(
  defaultContextValue
)

export default UserContext
