import { DEFAULT_DATE_FORMAT } from "../../../hooks/user/state/consts"
import { ICustomerPersonal } from "../../../types"
import { formatDate } from "../../../utils/utils"

export const parseBirthdate = (birthDate: string) => {
  const [birthdayDay, birthdayMonth, birthdayYear] = birthDate
    .split(".")
    .map((string) => +string)

  return [birthdayDay, birthdayMonth, birthdayYear]
}

type IBuildBirthDateOptions = {
  birthdayDay: ICustomerPersonal["birthday_day"]
  birthdayMonth: ICustomerPersonal["birthday_month"]
  birthdayYear: ICustomerPersonal["birthday_year"]
}

export const buildDate = (
  options: IBuildBirthDateOptions | undefined
): string => {
  if (!options) return ""

  const { birthdayDay, birthdayMonth, birthdayYear } = options

  if (!birthdayYear || !birthdayMonth || !birthdayDay) {
    return ""
  }

  return formatDate(
    +new Date(`${birthdayYear}/${birthdayMonth}/${birthdayDay}`),
    "yyyy-MM-dd",
    DEFAULT_DATE_FORMAT
  )
}
