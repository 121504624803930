import { createContext } from "react"

import { IProductServiceContext } from "./types"

const ProductServiceContext = createContext<IProductServiceContext>({
  activeCallSessionData: undefined,
  error: undefined,
  productIsActive: false,
  goToStep: () => ({}),
  avatar: undefined,
  productType: "chat",
  shouldShowRatingReminder: false,
  showRatingReminder: () => ({}),
})

export default ProductServiceContext
