import {
  IListingNumber,
  IUser,
  ErrorCode,
  ICustomerProfile,
  ICustomerBalance,
} from "../../types"
import { IProductFlowDataOrNull } from "../../ui-lib/CallFlow/CallFlowService/types"

export enum USER_ACTION_TYPE {
  SET_INITIAL_USER_DATA = "SET_INITIAL_USER_DATA",
  RESET_USER_STATE = "RESET_USER_STATE",
  UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS",
  UPDATE_USER_BALANCE = "UPDATE_USER_BALANCE",
  UPDATE_USER_DETAILS_ERROR = "UPDATE_USER_DETAILS_ERROR",
  UPDATE_USER_EMAIL_ERROR = "UPDATE_USER_EMAIL_ERROR",
  SET_PRODUCT_FLOW_SETTINGS = "SET_PRODUCT_FLOW_SETTINGS",
  RESET_PRODUCT_FLOW_SETTINGS = "RESET_PRODUCT_FLOW_SETTINGS",
  VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_ERROR = "VERIFY_EMAIL_ERROR",
  SEND_VERIFICATION_EMAIL_SUCCESS = "SEND_VERIFICATION_EMAIL_SUCCESS",
  RESET_SEND_VERIFICATION_EMAIL_DATA = "RESET_SEND_VERIFICATION_EMAIL_DATA",
  PREFERENCES_LOADING = "PREFERENCES_LOADING",
  SET_PREFERENCES = "SET_PREFERENCES",
  UPDATE_PREFERENCE_GROUPS = "UPDATE_PREFERENCE_GROUPS",
  UPDATE_PREFERENCES_ERROR = "UPDATE_PREFERENCES_ERROR",
  GET_PREFERENCES_ERROR = "GET_PREFERENCES_ERROR",
}

export interface IUserAction {
  type: USER_ACTION_TYPE
  payload?:
    | IListingNumber
    | IInitialCustomerDataPayload
    | ICustomerDataPayload
    | IProductFlowDataOrNull
    | Partial<IUser>
    | Balance // Deprecated
    | ICustomerBalance
    | IPreferencesPayload
    | IPreference[]
    | IPreferenceGroupData[]
    | IDataLoadingPayload
    | ErrorCode
    | Error
    | Error[]
    | null
}

export interface IDataLoadingPayload {
  loading: boolean
}

export interface ICustomerProfileData {
  details: Partial<ICustomerProfile>
  errors?:
    | {
        [key: string]: Readonly<Error[]>
      }
    | undefined
}

export type ICustomerDataPayload = ICustomerProfileData

export interface IPreferencesPayload {
  loading: boolean
  groups: IPreferenceGroupData[]
}

export interface IVerifyEmailData {
  loading?: boolean
  error?: string | null
  result?: boolean
}

export interface IPreference {
  name: string
  description: string
  title: string
  enabled: boolean
}

export interface IPreferenceGroupData {
  name: string
  title: string
  items: IPreference[]
}

export interface IPreferencesData {
  groups: IPreferenceGroupData[]
  error: string | undefined
  loading: boolean | undefined
}

export interface UserState {
  user: ICustomerProfileData
  productFlowSettings: IProductFlowDataOrNull
  verifyEmail: IVerifyEmailData
  resendVerificationEmail: IVerifyEmailData
  preferencesData: IPreferencesData
  initialLoadReady: boolean
}

export interface Balance {
  balance: string
}

export interface BalanceResult {
  balance: Balance
}

export interface SendVerificationEmailResult {
  resendVerificationEmail: null
  errors?: Array<any>
}

export interface SendVerificationEmailVariables {
  userId: string
}

export interface IInitialCustomerDataPayload {
  initialLoadReady: boolean
  user: ICustomerProfileData | undefined | null
  // checkout expects balance to be available immediately
  // balance: BalanceResult | null
}

export interface IFetchPreferencesResult {
  user_id: number
  groups: IPreferenceGroupData[]
}
