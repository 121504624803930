import React from "react"

import UserContext from "./UserContext"
import useUserStateReducer from "./useUserReducer"
import { defaultUserState } from "./state"
import { IUser } from "../../types"

interface IUserProviderProps {
  user: IUser | undefined
  children: React.ReactNode
}

const UserProvider: React.FC<IUserProviderProps> = ({ children, user }) => {
  const initialUserState = user
    ? {
        ...defaultUserState,
        initialLoadReady: true,
        user: {
          ...defaultUserState.user,
          details: user,
        },
      }
    : defaultUserState

  const userState = useUserStateReducer(initialUserState)

  return (
    <UserContext.Provider value={userState}>{children}</UserContext.Provider>
  )
}

export default UserProvider
