import { useReducer, useMemo } from "react"

import useSWRMutation from "swr/mutation"
import { reducer, makeActions, makeSelectors } from "./state"
import { IFetchPreferencesResult, UserState } from "./types"
import {
  fetchWithConfig,
  IDefaultRequestConfig,
  IFetchWithConfigOptions,
} from "../../utils/rest"
import { useRuntimeConfig } from "../runtimeConfig"
import { ICustomerProfile } from "../../types"

const useUserStateReducer = (defaultUserState: UserState) => {
  const [userState, dispatch] = useReducer(reducer, defaultUserState)
  const selectors = makeSelectors(userState)

  const { getRuntimeConfig } = useRuntimeConfig()
  const { trigger } = useSWRMutation(
    "_",
    async (_, { arg, headers }: IFetchWithConfigOptions) => {
      return fetchWithConfig<ICustomerProfile | IFetchPreferencesResult>(
        { ...arg, headers },
        getRuntimeConfig,
        "advice"
      )
    }
  )
  const actions = useMemo(
    () =>
      makeActions(
        dispatch,
        trigger as <T>(extraArgument: IDefaultRequestConfig) => Promise<T>
      ),
    []
  )

  return {
    state: userState,
    ...selectors,
    ...actions,
  }
}

export default useUserStateReducer

export type TUseUserReducerHookResult = ReturnType<typeof useUserStateReducer>
