import React from "react"
import { IIconProps } from "./types"

const AlertsIcon: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
  color = "#F8B300",
}) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none">
    <path d="M9.52344 13.2002H8.39844V14.7002H9.52344V13.2002Z" fill={color} />
    <path d="M9.52344 7.34961H8.39844V12.2996H9.52344V7.34961Z" fill={color} />
    <path
      d="M9 4.05L14.7 15.375H3.3L9 4.05ZM9 1.5L1.5 16.5H16.5L9 1.5Z"
      fill={color}
    />
  </svg>
)

export default AlertsIcon
