import useSWRMutation from "swr/mutation"
import { ICallDetails, ICallQueueDetails } from "../../../types"

import {
  fetchWithConfig,
  IFetchWithConfigOptions,
  SWR_KEYS,
} from "../../../utils/rest"
import { getRuntimeConfig } from "../../../utils/runtimeConfig"
import { IStartProductSessionData } from "../../ChatFlow/ChatFlowService/types"

const useCallApi = () => {
  const {
    trigger: startCallSession,
    error: startCallSessionError,
  } = useSWRMutation(
    "call/start",
    async (
      url,
      { arg, headers }: IFetchWithConfigOptions<IStartProductSessionData>
    ) => {
      return fetchWithConfig<{ details: ICallDetails }>(
        {
          url,
          method: "POST",
          data: arg,
          headers,
        },
        getRuntimeConfig
      )
    }
  )

  const {
    trigger: getActiveCallSession,
    error: activeCallSessionError,
    isMutating: activeCallSessionLoading,
    reset: resetActiveCallSession,
    data: activeCallSessionData,
  } = useSWRMutation(
    "call",
    (
      url,
      { arg: { id }, headers }: IFetchWithConfigOptions<{ id: number }>
    ) => {
      return fetchWithConfig<{ details: ICallDetails }>(
        { url, data: { id }, method: "POST", headers },
        getRuntimeConfig
      )
    }
  )

  const { trigger: queueCallSession } = useSWRMutation(
    SWR_KEYS.CALL_QUEUE,
    async (
      url,
      { arg, headers }: IFetchWithConfigOptions<IStartProductSessionData>
    ) => {
      return fetchWithConfig<{
        details: ICallQueueDetails
      }>(
        {
          url,
          method: "POST",
          data: arg,
          headers,
        },
        getRuntimeConfig
      )
    }
  )

  return {
    startCallSession,
    startCallSessionError,
    getActiveCallSession,
    activeCallSessionError,
    activeCallSessionLoading,
    resetActiveCallSession,
    activeCallSessionData,
    queueCallSession,
  }
}

export default useCallApi
