import differenceInDays from "date-fns/differenceInDays"
import { utcToZonedTime } from "date-fns-tz"
import de from "date-fns/locale/de"
import { isValid, format, parseISO } from "date-fns"

export const isLessThenXDays = (date: string, daysNumber = 7): boolean => {
  const expirationDate = utcToZonedTime(new Date(date), "UTC")
  return differenceInDays(expirationDate, new Date()) < daysNumber
}

export const formatDate = (date: string): string => {
  const parsedDate = parseISO(date)
  const isValidDate = isValid(parsedDate)
  // eslint-disable-next-line no-console
  if (!isValidDate) console.log(`Date not valid ${date}`)
  return isValidDate ? format(parsedDate, "dd.MM.yyyy", { locale: de }) : "-"
}
