import { useCallback } from "react"

import { useCheckoutTracking } from "../../../../hooks/tracking"
import {
  ClientTrackingEventName,
  IAdvisorProduct,
  IAdvisorStatus,
} from "../../../../types"
import {
  getProductTrackingProperties,
  getClientTrackingProductStatus,
} from "../../utils"
import { useRuntimeConfig } from "../../../../hooks/runtimeConfig"

interface IUseProductTracking {
  trackProductEvent: (product: IAdvisorProduct, isReservation: boolean) => void
}

/**
 * @note useProductTracking can be removed and changed with useCheckoutTracking
 * as soon as trackExpertEvent will be removed.
 */
const useProductTracking = (
  listingNo: string,
  name: string,
  advisorStatus?: IAdvisorStatus
): IUseProductTracking => {
  const { getRuntimeConfig } = useRuntimeConfig()
  const { trackCheckoutEvent } = useCheckoutTracking()

  const trackProductEvent = useCallback(
    (product: IAdvisorProduct, isReservation: boolean) => {
      trackCheckoutEvent({
        eventName: ClientTrackingEventName.PRODUCT_ADD,
        status: advisorStatus && getClientTrackingProductStatus(advisorStatus),
        ...getProductTrackingProperties(
          listingNo,
          name,
          getRuntimeConfig,
          product,
          isReservation
        ),
      })
    },
    [getRuntimeConfig, listingNo, name, advisorStatus, trackCheckoutEvent]
  )

  return {
    trackProductEvent,
  }
}

export default useProductTracking
