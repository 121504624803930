import React from "react"
import clsx from "clsx"

import { TPromotionVariation, IPromotionBaseRestProps } from "../types"
import PromotionBase from "./PromotionBase"
import PromoIcon from "./PromoIcon"
import classes from "./FreeProductPromotion.module.scss"

interface IFreeProductPromotionProps extends IPromotionBaseRestProps {
  variation: TPromotionVariation
}

const FreeProductPromotion: React.FC<IFreeProductPromotionProps> = ({
  variation,
  testId = "freeOfferLabel",
  rootClassName: _rootClassName,
  children,
  ...rest
}) => {
  const rootClassName = clsx(classes.rootRibbon, _rootClassName)
  const isMinimal = variation === "minimal" || variation === "minimal-dense"
  const freeProductVariation = isMinimal ? variation : "ribbon"

  if (variation === "coupon-dense") {
    return null
  }

  return (
    <PromotionBase
      testId={testId}
      iconPosition={isMinimal ? "end" : "start"}
      variation={freeProductVariation}
      rootClassName={isMinimal ? _rootClassName : rootClassName}
      renderIcon={(props) => <PromoIcon {...props} type="gift" />}
      {...rest}
    >
      {children}
    </PromotionBase>
  )
}

export default FreeProductPromotion
