import React from "react"
import clsx from "clsx"
import { IAdvisorStatus } from "../../../types"
import { Box } from "../../_common"
import classes from "./StatusIndicator.module.scss"

interface IStatusIndicator {
  onlineStatus?: IAdvisorStatus | undefined
  className?: string
  children?: React.ReactNode
}

const StatusIndicator: React.FC<IStatusIndicator> = ({
  onlineStatus,
  children,
  className,
}) => {
  const onlineStatusClassName = clsx(
    classes.status,
    {
      [classes.online]: onlineStatus === "online",
      [classes.offline]: onlineStatus === "offline",
      [classes.busy]: onlineStatus === "busy",
    },
    className
  )

  return <Box className={onlineStatusClassName}>{children}</Box>
}
export default StatusIndicator
